import React from 'react';
import screenImage from '../images/screen.jpg';

function Description() {
  return (
    <div style={{
      height: '960px',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
      boxSizing: 'border-box'
    }}>
      <h2 style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '24px',
        textAlign: 'center'
      }}>
        Get the perfect look instantly
      </h2>
      <p style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '18px',
        color: '#666',
        marginBottom: '32px',
        textAlign: 'center',
        maxWidth: '800px',
        lineHeight: '1.6'
      }}>
        With our one-tap face editor, you can enhance the beauty of your photos with just one click. Our beauty filters are fast, easy to use and have the ability to make any selfie or photo look absolutely stunning.
      </p>
      <img 
        src={screenImage} 
        alt="App Screenshot" 
        style={{
          maxWidth: '100%',
          maxHeight: 'calc(100% - 300px)', // Оставляем больше места для текста
          objectFit: 'contain'
        }}
      />
    </div>
  );
}

export default Description;