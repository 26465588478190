import React from 'react';
import appStoreImage from '../images/app-store.png';

function Footer() {
  return (
    <footer style={{
      height: '656px',
      backgroundColor: '#15161b',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '60px 0 40px',
      boxSizing: 'border-box'
    }}>
      <h2 style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '36px',
        color: '#cccccc',
        marginTop: '80px',
        marginBottom: '0px',
        textAlign: 'center'
      }}>
        Get FREY Now
      </h2>
      
      <a 
        href="https://apps.apple.com/app/id6736707486" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{
          display: 'inline-block',
          padding: '0',
          borderRadius: '15px',
          border: '1px solid white',
          backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }}
      >
        <img 
          src={appStoreImage} 
          alt="Download on App Store" 
          style={{ 
            cursor: 'pointer',
            height: '72px'
          }} 
        />
      </a>
      
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          marginBottom: '20px'
        }}>
          <a href="/privacy_policy_frey.pdf" style={{color: '#999999', textDecoration: 'none', fontSize: '14px'}}>Privacy Policy</a>
          <a href="/terms_of_use_frey.pdf" style={{color: '#999999', textDecoration: 'none', fontSize: '14px'}}>Terms of Use</a>
        </div>
        <a href="mailto:contact@freyapp.com" style={{color: '#777777', textDecoration: 'none', fontSize: '12px'}}>
          contact@freyapp.com
        </a>
      </div>
    </footer>
  );
}

export default Footer;