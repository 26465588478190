import React from 'react';
import Header from './Header.js';
import Description from './Description.js';
import Features from './Features.js';
import Footer from './Footer.js';

function App() {
  return (
    <div>
      <Header />
      <Description />
      <Features />
      <Footer />
    </div>
  );
}

export default App;
