import React from 'react';
import appStoreImage from '../images/app-store.png'; // Убедитесь, что путь к изображению правильный

function Header() {
  return (
    <header style={{
      height: '676px',
      backgroundColor: '#00dcff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div style={{
        padding: '0 50px',
        textAlign: 'center',
        maxWidth: '100%'
      }}>
        <h1 style={{
          color: 'white',
          fontFamily: 'Arial, sans-serif',
          fontSize: '48px',
          marginBottom: '10px'
        }}>FREY</h1>
        <h2 style={{
          color: 'white',
          fontFamily: 'Arial, sans-serif',
          fontSize: '24px',
          marginBottom: '5px'
        }}>One-Tap Face Editor</h2>
        <h3 style={{
          color: 'white',
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          marginBottom: '20px'
        }}>Fast & Simple Beauty Filters</h3>
        <a href="https://apps.apple.com/app/id6736707486" target="_blank" rel="noopener noreferrer">
          <img 
            src={appStoreImage} 
            alt="Download on App Store" 
            style={{ 
              cursor: 'pointer',
              height: '48px',
              maxWidth: '100%'
            }} 
          />
        </a>
      </div>
    </header>
  );
}

export default Header;