import React, { useState, useEffect } from 'react';
import eyeImage from '../images/eye.svg';
import handImage from '../images/hand.png';
import makeupImage from '../images/makeup.svg';

const Features = () => {
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = {
    minHeight: '656px',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '60px 20px',
    boxSizing: 'border-box'
  };

  const featuresContainerStyle = {
    display: 'flex',
    flexDirection: isVertical ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: isVertical ? 'center' : 'flex-start',
    width: '100%',
    maxWidth: '1200px',
    marginTop: '60px'
  };

  const featureStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: isVertical ? '100%' : '30%',
    maxWidth: isVertical ? '400px' : 'none',
    padding: '30px',
    boxSizing: 'border-box',
    marginBottom: isVertical ? '60px' : '0'
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    marginBottom: '30px'
  };

  const titleStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '28px',
    color: 'black',
    marginBottom: '20px',
    textAlign: 'center'
  };

  const descriptionStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '18px',
    color: '#666',
    textAlign: 'center',
    lineHeight: '1.6'
  };

  return (
    <div style={containerStyle}>
      <div style={{
        width: '100%',
        maxWidth: '800px',
        textAlign: 'center'
      }}>
        <h2 style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '42px',
          color: 'black',
          marginBottom: '20px',
          textAlign: 'center'
        }}>
          Features
        </h2>
        <p style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '22px',
          color: '#666',
          textAlign: 'center',
          marginBottom: '40px'
        }}>
          Perfect Beauty Filters
        </p>
      </div>
      
      <div style={featuresContainerStyle}>
        <div style={featureStyle}>
          <img src={eyeImage} alt="Natural Beauty" style={imageStyle} />
          <h3 style={titleStyle}>Natural Beauty</h3>
          <p style={descriptionStyle}>Top-notch AI filters adapt to your face and simply highlight your natural beauty.</p>
        </div>
        <div style={featureStyle}>
          <img src={handImage} alt="Easy to use" style={imageStyle} />
          <h3 style={titleStyle}>Easy to use</h3>
          <p style={descriptionStyle}>Just upload a photo and tap on a filter - it is as simple as that!</p>
        </div>
        <div style={featureStyle}>
          <img src={makeupImage} alt="Makeup Filters" style={imageStyle} />
          <h3 style={titleStyle}>Makeup Filters</h3>
          <p style={descriptionStyle}>The most realistic makeup filters are ready to give your look a whole new expression.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;